import { Helper } from './modules/helper.js';

let helper = new Helper();

$(document).ready(function() {

	helper.logLoaded();

	$('[data-toggle="offcanvas"]').on('click', function () {
	    $('.offcanvas-collapse').toggleClass('open');
	})

	$('.hamburger').on('click', function () {
		$(this).toggleClass('is-active');
	});

})
